html {
  background-color: #fff !important;
}
:root {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  font-weight: 400;
  background-color: #fff !important;
}

.main {
  background-color: white !important;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  min-height: 100vh;
}

h1 {
  font-size: 1.5em;
  line-height: 1.1;
}

#submit-btn {
  border-radius: 8px;
  border: 2px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: 0.5s ease;
  background-color: rgba(129, 140, 248);
  color: white;
}
.question img {
  width: auto;
}
@media screen and (max-width: 786px) {
  .question img {
    width: 250px;
    height: 140px;
  }
}

.feedback #submit-btn:focus {
  background-color: rgba(129, 140, 248);
  color: white;
  box-shadow: none;
}

#submit-btn:hover,
#submit-btn:focus {
  outline: none;
  color: black;
  border-color: rgba(129, 140, 248);
  background-color: #fff;
  box-shadow: 0 0 0 5px rgb(129 140 248 / 30%);
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #dfdfdf;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

.input {
  width: 15rem;
  height: 45px;
  line-height: 30px;
  padding: 0 1rem;
  padding-left: 1rem;
  border: 2px solid rgba(129, 140, 248);
  border-radius: 10px;
  outline: none;
  background-color: #f5faff;
  color: #0d0c22;
  transition: 0.5s ease;
}

.input::placeholder {
  color: #8698b1;
}

.input:focus,
.input:hover {
  outline: none;
  border-color: rgba(129, 140, 248);
  background-color: #fff;
  box-shadow: 0 0 0 5px rgb(129 140 248 / 30%);
}
.main {
  width: max-content;
  padding: 1rem 2rem 2.7rem 2rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 1rem;
  text-align: left;
}
.mainReferee {
  padding: 2rem 4rem;
  text-align: center;
}
.mainReferee .label {
  gap: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
}
.form p {
  position: absolute;
  top: 2.5rem;
  font-size: 12px;
  color: red;
}
#root {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: 90vh !important;
}
@media screen and (max-width: 768px) {
  #root,
  html,
  body {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }

  body {
    min-height: 90vh !important;
  }
  .main {
    /* text-align: center; */
    width: 80%;
    margin: auto;
  }
  .mainReferee {
    padding: 1rem;
  }
  .mainReferee .label {
    width: 60%;
    margin: 15px auto;
    gap: 30px !important;
  }
  .input {
    width: unset;
    padding-left: 10px;
  }
}

.email-not-found > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.email-not-found > div > button {
  padding: 10px 15px !important;
  font-size: 15px !important;
  width: 100%;
}

/* =============================================== */

.radio-input-wrapper {
  padding: 2rem 4rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 1rem;
}

.label {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 14px 16px;
  margin: 5px 0;
  cursor: pointer;
  transition: 0.3s;
}

.label:hover,
.label:focus-within,
.label:active {
  background: hsla(0, 0%, 53%, 0.14);
}

.radio-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.radio-design {
  width: 17px;
  height: 17px;
  border-radius: 100px;
  background: linear-gradient(
    to right bottom,
    hsl(154, 97%, 62%),
    hsl(225, 97%, 62%)
  );
  position: relative;
}

.radio-design::before {
  content: "";
  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: 0.3s;
}

.radio-input:checked + .radio-design::before {
  transform: scale(0);
}

.radio-input-wrapper > p {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}

.label-text {
  max-width: 80%;
  color: hsl(0, 0%, 49%);
  margin-left: 15px;
  font-size: 15px;
  transition: 0.3s;
}
.main-div-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.main-div-btn #submit-btn {
  width: 47%;
  margin: 0 auto;
}

.radio-input:checked ~ .label-text {
  color: hsl(0, 0%, 20%);
}

.main_div {
  max-width: 600px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  padding: 2rem 2rem 2.5rem 2rem !important;
}

@media (max-width: 768px) {
  .radio-input-wrapper {
    padding-inline: 2.5rem;
    padding-block: 1.5rem;
  }
  .main_div {
    padding: unset !important;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
  }
  .label-text {
    font-size: 13px;
  }
  .main-div-btn #submit-btn {
    width: 90%;
    margin: 0 auto;
  }
  .main-div-btn {
    flex-direction: column;
    gap: 15px;
  }
  .radio-input-wrapper > p {
    width: 92%;
    margin: 5px auto;
    font-size: 15px;
    font-weight: 600;
  }
  .radio-input-wrapper #submit-btn {
    width: 80% !important;
  }
  .radio-input-wrapper .label {
    margin: auto 10px;
  }
}

.radio-input-wrapper #submit-btn {
  width: 40%;
}

.radio-input-wrapper .label {
  text-align: left;
}

.quizSubmitted > div > a,
form button {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #55cd6c;
  text-decoration: none;
  color: white;
  width: auto;
  padding: 0 15px;
  border-radius: 50px;
  margin-top: 20px;
}

.registerForm form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.registerForm form div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}
.registerForm form div input,
select {
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0.7rem 10px;
  border: 2px solid #959595;
  border-radius: 5px;
  transition: 0.2s linear;
}

.registerForm form div label {
  font-weight: 500;
}
.registerForm > p {
  font-size: 15px;
}
.registerForm form div input:hover,
.registerForm form div input:focus,
.registerForm form div select:hover,
.registerForm form div select:focus {
  outline: none;
  color: black;
  border: 2px solid rgba(129, 140, 248);
  background-color: #fff;
  box-shadow: 0 0 0 5px rgb(129 140 248 / 30%);
}

.correctAnswer,
.wrongAnswer {
  padding: 0 1.5rem;
  border-radius: 10px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.correctAnswer {
  background-color: #55cd6c;
}

.wrongAnswer {
  background-color: #f44336;
}
